// src/ConnectWallet.js
import React, { useState, useEffect } from "react";
import getWeb3 from "../helper/getweb3";

const ConnectWallet = ({ cb }) => {
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [error, setError] = useState("");

  const initWeb3 = async () => {
    try {
      const web3Instance = await getWeb3();
      setWeb3(web3Instance);
      const userAccounts = await web3Instance.eth.getAccounts();
      setAccounts(userAccounts);
    } catch (err) {
      setError("Failed to load web3 or accounts.");
      console.error(err);
    }
  };
  useEffect(() => {
    // initWeb3();
  }, []);

  const handleConnectWallet = async () => {
    await initWeb3();
    if (!web3) {
      console.error("Web3 is not initialized-.");
      return;
    }
    try {
      const userAccounts = await web3.eth.requestAccounts();
      setAccounts(userAccounts);
    } catch (err) {
      setError("Error connecting to wallet.");
      console.error(err);
    }
  };

  useEffect(() => {
    if (accounts[0]) {
      cb(accounts[0]);
    }else{
      cb('')
    }
  }, [accounts]);

  return (
    <div className="mx-2">
      {/* <h1>Connect Wallet</h1> */}
      {accounts.length > 0 ?
        <button className=" btn btn-success" onClick={()=>{
          setAccounts([]);
        }}>DisConnect</button>
      
       : (
        <button onClick={handleConnectWallet} className=" btn btn-dark">Connect</button>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default ConnectWallet;
