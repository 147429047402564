import React, { useEffect, useState } from "react";
import "./App.css";
import {
  createDepositRequest,
  getBaseAndQuotePrice,
  getDepositStatus,
} from "./helper/apis";
import QRCode from "react-qr-code";
import ConnectWallet from "./component/connectWallet";

function Buy() {
  const data = [
    {
      label: "BNB (Smart Chain)",
      value: "bscscan",
      token: "BNB",
      type: "native",
      index: 0,
      logo: "img/images.png",
    },
    {
      label: "MATIC (Polygon)",
      value: "polygon",
      token: "MATIC",
      type: "native",
      index: 2,
      logo: "img/matic.png",
    },
    {
      label: "USDT (Polygon)",
      value: "polygon",
      token: "USDT",
      type: "polygonToken",
      index: 3,
      logo: "img/usdt.png",
    },
    {
      label: "USDT (BEP20)",
      value: "bscscan",
      token: "USDT",
      type: "BEP20",
      index: 4,
      logo: "img/usdt.png",
    },
  ];
  const [RecieverAddress, setRecieverAddress] = useState("");
  const [index, setindex] = useState("null");
  const [Amount, setAmount] = useState("");
  const [response, setresponse] = useState({});
  const [openqr, setopenqr] = useState(false);
  const [price, setPrice] = useState({ baseprice: 0, quoteprice: 0 });
  const [show, setShow] = useState(false);
  var tt;

  async function fetchBuyToken() {
    try {
      if (RecieverAddress && openqr) {
        const res = await getDepositStatus(RecieverAddress);
        clearTimeout(tt);
        if (res?.data?.success) {
          setresponse(res?.data);
        } else {
          if (Object.keys(response)?.length == 0 && res?.data) {
            setresponse(res?.data);
          }
          tt = setTimeout(() => {
            fetchBuyToken();
          }, 3500);
        }
      }
    } catch (error) {
      console.error("Error fetching the buy token", error);
    }
  }

  async function createRequest() {
    if(!price?.baseprice || !price?.quoteprice){      
      alert("Token price not fetched");
      return;
    }
    if (index != "null") {
      console.log(index);
      const res = await createDepositRequest(
        RecieverAddress,
        data[index].value,
        data[index].token,
        Amount
      );
      console.log(res, "res");
      if (res?.status == 200) {
        setopenqr(true);
      }
    } else {
      alert("Please select coin, that you want to pay");
    }
  }

  useEffect(() => {
    fetchBuyToken();
  }, [openqr, RecieverAddress]);

  const copyToClipboard = async () => {
    let textToCopy = response?.paymentAddress;

    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(textToCopy);
      alert("Payment address copied!");
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";
      document.body.prepend(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        alert("Payment address copied!");
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
    console.log(response?.paymentAddress, "resonse");
  };

  // const copyToClipboard = () => {
  //   navigator.clipboard
  //     .writeText(response?.paymentAddress)
  //     .then(() => {
  //       alert("Payment address copied!");
  //     })
  //     .catch((err) => {
  //       console.error("Failed to copy: ", err);
  //     });
  // };

  useEffect(() => {
    if (response?.success) {
      setTimeout(() => {
        setresponse({});
        setopenqr(false);
        setRecieverAddress("");
        setAmount("");
      }, 6000);
    }
  }, [response]);

  async function getprc(i) {
    if (i >= 0) {
      const quote = data[i];
      const base = "DTBX";
      // console.log(index,quote,base,'all tkoen info');
      // (async () => {
      const resp = await getBaseAndQuotePrice(base, quote.token);
      console.log(resp?.data, "price response");
      if (resp?.data) {
        setPrice(resp?.data);
      }
      // })();
    }
  }
 
  useEffect(()=>{
    getprc(0);
  },[])
  // console.log(Number(Number(Amount) / Number(price?.baseprice)),Amount,price?.baseprice)
  return (
    <>
    <section>
        <div class="container mt-3">
          <div class="card pink_img_bg dao_banner">
            <div class="card-body">
              <div>
                <div class="">
                  <h3 class="fw-bold text-shadow text-center mb-2 p-2 border-bottom text-white">
                  Buy DTBX using the following payment methods
                  </h3>
                </div>
                <ul class="token-update__data  text-shadow">
                  <li>
                    <span class="text-shadow">
                    BNB (Binance Coin)
                    </span>
                    <p>Quickly and easily purchase DTBX with Binance Coin (BNB). Benefit from lower transaction fees and fast processing times.
                    MATIC (Polygon)</p>
             
                    <p>Use MATIC to buy DTBX on the Polygon network. Enjoy the advantages of low gas fees and high transaction speeds.</p>
                  </li>
                  <li>
                    <span>
                    USDT (Tether) on Polygon
                    </span>
                    <p> Purchase DTBX with USDT on the Polygon network. Experience secure and swift transactions with minimal fees.</p>
                  </li>
                  <li>
                    <span>
                    USDT (Tether) on BEP-20
                    </span>
                    <p>Buy DTBX using USDT on the BEP-20 network. Take advantage of Binance Smart Chain's efficient and cost-effective transactions.</p>
                  </li>
                </ul>
                {/* <div>
                  <ul class="token-update__data text-shadow">
                    <li>
                      <span>Minimum Staking </span> <strong> 100 DTBX</strong>
                    </li>
                    <li>
                      <span>Maximum Staking </span>{" "}
                      <strong> 10,00,000 DTBX</strong>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    <div class="buy-page mx-auto d-block">
      
      <div className="container">
        <div class="text-center">
          <h1 className="heading_point">Buy DTBX</h1>
         
        </div>
        <div class="text-end mb-4">
        <h6 className="">DTBX Price-${price?.baseprice?.toFixed(5)}</h6>
         
        </div>

        <div className="card cardBg ">
          <div className="card-body cardBgInner">
            {Object.values(response)?.length == 0 ? (
              <div className="p-2 mt-3">
                <div className="div-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">DTBX</div>
                    </div>
                    <input
                      type="text"
                      className="form-control placeholdercolor"
                      placeholder="Number Of DTBX"
                      value={Amount}
                      onChange={(e) => setAmount(e?.target?.value)}
                      required
                    />
                  </div>
                  <div className="input-group mt-3">
                    <div
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control placeholdercolor"
                        placeholder="DTBX Receiver Address"
                        value={RecieverAddress}
                        onChange={(e) => setRecieverAddress(e?.target?.value)}
                        required
                      />
                      <ConnectWallet
                        cb={(data) => {
                          // console.log(data,"cb data")
                          setRecieverAddress(data);
                        }}
                      />
                    </div>
                  </div>
                  <div class="form-group mt-4">
                    <div
                      disabled
                      selected
                      onClick={() => {
                        setShow(!show);
                      }}
                      style={{ cursor: "pointer" }}
                      class="form-control"
                    >
                      {index >= 0
                        ? data[index]?.label
                          ? data[index]?.label
                          : "Select token"
                        : "Select Payment  Method"}
                    </div>
                    <div
                      class="form-control "
                      id="exampleFormControlSelect1"
                      onChange={(v) => setindex(v?.target?.value)}
                      style={{
                        position: "absolute",
                        zIndex: 100,
                        width: "94%",
                        border: "1px solid rgba(0,0,0,0.2)",
                      }}
                      className={!show ? "d-none" : ""}
                    >
                      {data?.map((it, i) => {
                        {
                          /* {console.log(index,i)} */
                        }
                        return (
                          <div
                            key={i + "selectblc"}
                            onClick={() => {
                              getprc(i);
                              setindex(i);
                              setShow(false);
                            }}
                            style={{
                              backgroundColor:
                                index == i && index != "null"
                                  ? "rgb(0, 12, 26)"
                                  : "#fff",
                              color: index == i ? "#fff" : "#000",
                              paddingInline: 10,
                              cursor: "pointer",
                              borderTop: "1px solid rgba(0,0,0,0.2)",
                              paddingBlock: 7,
                            }}
                            className={!show ? "d-none" : ""}
                          >
                            <img
                              src={it?.logo}
                              style={{
                                height: 25,
                                width: 25,
                                borderRadius: 50,
                              }}
                              className="mx-2"
                            />
                            {it.label}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div>
                  <h6 className="colorvalue mt-1">
                    {index != "null" ? data[index]?.token + " value " : ""}
                    {Amount && index != "null"
                      ? data[index]?.token != "USDT"
                        ? price?.quoteprice > 0
                          ? (
                              (Amount * price?.baseprice) /
                              price.quoteprice
                            )?.toFixed(6)
                          : 0
                        : Number(
                            Number(Amount) * Number(price?.baseprice)
                          ).toFixed(3)
                      : 0}
                  </h6>
                </div>
                <button
                  // type="submit"
                  className="btn btn-buy mt-4 mb-3"
                  onClick={() => createRequest()}
                >
                  Buy&nbsp;<i class="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            ) : null}

            {Object.values(response)?.length ? (
              <div className="p-2 mt-3">
                <div className="form-group">
                  <div class="text-center mb-3">
                    {response?.success ? (
                      <img
                        src={
                          response?.success
                            ? "img/pymentdone.png"
                            : "img/images.png"
                        }
                        style={{
                          height: 300,
                          width: 300,
                        }}
                      />
                    ) : (
                      <QRCode
                        size={156}
                        style={{
                          height: "auto",
                          // maxWidth: "100%",
                          // width: "100%",
                        }}
                        value={response?.paymentAddress}
                        viewBox={`0 0 156 156`}
                      />
                    )}
                  </div>
                  <div>
                    <h6 className="coloramount">
                      {index != "null" ? data[index]?.token + " " : ""}
                      {Amount && index != "null"
                        ? data[index]?.token != "USDT"
                          ? price?.quoteprice > 0
                            ? (
                                (Amount * price?.baseprice) /
                                price.quoteprice
                              )?.toFixed(4)
                            : 0
                          : Number(
                              Number(Amount) * Number(price?.baseprice)
                            ).toFixed(3)
                        : 0}
                    </h6>
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Payment Address"
                      value={response?.paymentAddress}
                      // onChange={(e) => setPaymentAddress(e.target.value)}
                    />
                    <button
                      className="btn btn-secondary"
                      onClick={copyToClipboard}
                    >
                      Copy
                    </button>
                  </div>
                </div>

                {/* <button
                // type="submit"
                className="btn btn-buy mt-4 mb-3"
                // onclick="QRToken()"
              >
                submit &nbsp;<i class="fa-solid fa-arrow-right"></i>
              </button> */}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Buy;
