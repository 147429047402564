import React from 'react';

const Footer = () => {
  return (
    <footer className="footer-main bg-light mt-5">
      <div className="text-white pt-3 pb-3">
        <div className="pb-0">
          <div className="row align-items-center justify-content-center flex-column-reverse flex-sm-row">
            <div className="col-sm-12 col-md-6">
              <div className="d-flex justify-content-center">
                <div>
                  <div className='logofooter'>
                    <img width="200px" src="img/dtbx__6.png" alt="" />
                  </div>
                  <div className="text-start p-3 fs-6 text-dark">
                    Copyright © 2024 All Rights Reserved.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="d-flex justify-content-center">
                <div className="mb-4">
                  <a
                    className="btn btn-outline-dark btn-floating m-1 round_radius"
                    href="https://www.facebook.com/profile.php?id=61558631430948"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    className="btn btn-outline-dark btn-floating m-1 round_radius"
                    href=" https://twitter.com/GlobalCC_"
                    target="_blank"
                    role="button"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  
                  <a
                    className="btn btn-outline-dark btn-floating m-1 round_radius"
                    href="https://www.instagram.com/global.cc_"
                    target="_blank"
                    role="button"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    className="btn btn-outline-dark btn-floating m-1 round_radius"
                    href="https://www.youtube.com/@GlobalCC_"
                    target="_blank"
                    role="button"
                    rel="noopener noreferrer"
                  >
                   <i class="fa-brands fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
