import logo from './logo.svg';
import './App.css';
import Buy from './Buy';
import Header from './Header';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
    <Header/>
    <Buy/>
    <Footer/>
   
    </div>
  );
}

export default App;
