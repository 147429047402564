import React from 'react';

function Header() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-header">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="img/dtbx__6.png" className='imglogo'/>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item layout">
                <a className="nav-link active" aria-current="page" href="https://explorer.dotblox.io/" target="_blank" >Explorer</a>
              </li>
              <li className="nav-item layout">
                <a className="nav-link" href="https://staking.dotblox.io" target="_blank">Staking</a>
              </li>
              <li className="nav-item layout">
                <a className="nav-link" href="https://swap.dotblox.io/" target="_blank">Swap</a>
              </li>
              <li className="nav-item layout">
                <a className="nav-link" href="https://xtring.network/" target="_blank">Crosschain</a>
              </li>
              {/* <li className="nav-item layout">
                <a className="nav-link" href="https://createtoken.dotblox.io/" target="_blank">Create Token</a>
              </li> */}
              <li className="nav-item layout">
                <a className="nav-link" href="https://dtbx.exchange/" target="_blank">Exchange</a>
              </li>
              <li className="nav-item layout">
                <a className="nav-link" href="https://status.dotblox.io/" target="_blank">Status</a>
              </li>
              <li className="nav-item layout">
                <a className="nav-link" href="https://www.dtbx.travel/" target="_blank">Travel</a>
              </li>
              <li className="nav-item layout">
                <a className="nav-link" href="https://www.dtbx.shopping/" target="_blank">Shopping</a>
              </li>
              <li className="nav-item layout">
                <a className="nav-link" href="https://rewardtoken.dotblox.io/" target="_blank">Reward Token</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
