
// const url  ="http://localhost:3131/swap";
const url = "https://api.dtbx.exchange/swap";

export function createDepositRequest(userAddress, chain, token, amount){
   return fetch(url+"/deposit-request",{
    method:"POST",
    headers:{
        'content-type':'application/json',
        'access-control-allow-origin':'*',
    },
    body:JSON.stringify({userAddress, chain, token, amount}),
   }).then((res)=>res.json())
   .catch((e)=>console.log(e,"Error in createDepositRequest()"));
}

export function getDepositStatus(userAddress){
    return fetch(url+"/deposit-status",{
        method:"POST",
        headers:{
            'content-type':'application/json',
            'access-control-allow-origin':'*',
        },
        body:JSON.stringify({userAddress:userAddress}),
       }).then((res)=>res.json())
       .catch((e)=>console.log(e,"Error in getDepositStatus()"));
}

export function getBaseAndQuotePrice(base,quote){
   return fetch(url+'/calculate-base',{
    method:"POST",
    headers:{
        'content-type':'application/json',
        'access-control-allow-origin':'*',
    },
    body:JSON.stringify({basetoken:base, quotetoken:quote}),
   }).then((res)=>res.json())
   .catch((e)=>console.log(e,"Error in getBaseAndQuotePrice()"));
}